<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    filterPanelBlocks?: string[][];
    filterTitles?: string[];
    controlFilters?: boolean;
    xValues: string[];
    xValues2?: string[]; // second dropdown for index
    xValuesTitles?: string[];
    xValues2Titles?: string[];
    yValues?: string[];
    scenarios?: string[];
    years?: string[];
    disableYears?: boolean;
    controlYears?: boolean;
    year?: string;
    controlPillars?: boolean;
    showPillars?: boolean;
    pillars?: string[];
    pillarSelected?: string;
    scenario?: string;
    xSelected?: string;
    x2Selected?: string;
    ySelected?: string;
    xTitle?: string;
    isBarChart?: boolean;
    isDoubleChart?: boolean;
    isCompanyRanking?: boolean;
  }>(),
  {
    filterPanelBlocks: () => [],
    filterTitles: () => [],
    controlFilters: true,
    xValues2: () => [],
    xValuesTitles: () => [],
    xValues2Titles: () => [],
    yValues: () => [],
    scenarios: () => [],
    years: () => ["2030", "2050"],
    disableYears: false,
    controlYears: true,
    year: "",
    controlPillars: true,
    showPillars: false,
    pillars: () => [],
    pillarSelected: "",
    scenario: "",
    xSelected: "",
    x2Selected: "",
    ySelected: "",
    xTitle: "",
    isBarChart: false,
    isDoubleChart: false,
    isCompanyRanking: false,
  },
);

const emit = defineEmits([
  "onXSelect",
  "onX2Select",
  "onYSelect",
  "onFilterChange",
  "onClearClick",
  "onScenario",
  "onYear",
  "onSort",
  "onTitleUpdate",
  "onPillars",
  "onPillar",
]);

const xSelected = toRef(props, "xSelected");
const x2Selected = toRef(props, "x2Selected");
const ySelected = toRef(props, "ySelected");

const onFilterChange = (checked: boolean, id: string, index: number) => {
  emit("onFilterChange", checked, id, index);
};

const yearToShow = computed(() =>
  xSelected.value !== "Companies" ? props.years.join(" & ") : props.year,
);

const xToShow = computed(() => {
  return props.xValuesTitles.length && xSelected.value
    ? props.xValuesTitles[props.xValues.indexOf(xSelected.value)]
    : xSelected.value;
});

const title = computed(() =>
  props.isCompanyRanking
    ? `Companies’ ${String(props.year)} ${String(xToShow.value)}`
    : `${String(xToShow.value)}' ${
      props.isBarChart
        ? "Cost Breakdown"
        : (ySelected.value === "EBIT Margin" || ySelected.value === "Costs")
          && props.scenario === "All Scenarios"
            ? String(ySelected.value) + " Range"
            : String(ySelected.value)
    } in ${
      String(props.scenario)
      + (props.scenario === "All Scenarios" ? "" : " Scenario")
    } in ${String(yearToShow.value)}`,
);

watch(
  title,
  (value) => {
    emit("onTitleUpdate", value);
  },
  { immediate: true },
);

const yBreakdown = ref<HTMLElement>();

const onScenario = (title: string) => {
  if (
    props.scenario === "High Climate Impact"
    && ySelected.value === "Feed Additives"
  ) {
    const selected = yBreakdown.value?.children[0]?.children[0]?.children[1];
    if (selected) selected.innerHTML = "Costs";
  }
  emit("onScenario", title);
};

const regionsIndex = props.filterTitles.findIndex((title) => title === "Regions");
const proteinsIndex = props.filterTitles.findIndex((title) => title === "Proteins");
const companiesIndex = props.filterTitles.findIndex((title) => title === "Companies");
</script>

<template>
  <div class="flex flex-col gap-3">
    <div
      class="flex flex-wrap items-center justify-between pb-2 pt-4 md:flex-nowrap"
    >
      <div class="flex w-full flex-wrap items-center justify-between gap-2">
        <h3 class="mb-3 md:mb-0">
          {{ title }}
        </h3>
        <UiToggle
          v-if="controlPillars"
          id="pillarToggle"
          label="Pillar scores (beta)"
          :value="showPillars"
          @select="emit('onPillars', $event)"
        />
      </div>

      <UiFilterPanel
        v-if="!isCompanyRanking"
        :titles="filterTitles"
        :blocks="filterPanelBlocks"
        :disabled="isDoubleChart"
        scroll-on-open
        @on-filter-change="onFilterChange"
        @on-clear-click="emit('onClearClick', $event)"
      />
    </div>

    <div class="flex items-center gap-3 max-sm:flex-wrap max-sm:gap-2">
      <UiDropdown
        :label="xTitle || 'X Value'"
        :options="xValues"
        :option-names="xValuesTitles"
        :selected-option="xSelected"
        @select="
          (value: string, index: number) => {
            xSelected = value;
            emit('onXSelect', value, index);
          }
        "
      />
      <UiDropdown
        v-if="!!xValues2.length"
        id="kpi"
        class="min-w-[145px] max-w-[300px]"
        label="KPI"
        :options="xValues2"
        :option-names="xValues2Titles"
        :selected-option="x2Selected"
        :disabled="showPillars"
        @select="
          (value: string, index: number) => {
            x2Selected = value;
            emit('onX2Select', value, index);
          }
        "
      />
      <UiDropdown
        v-if="!!yValues.length"
        :ref="isBarChart ? 'yBreakdown' : 'yDropdown'"
        class="max-w-[300px]"
        label="Y Value"
        :options="yValues"
        :selected-option="ySelected"
        :disabled="showPillars"
        placeholder="Select a specific KPI"
        @select="
          (value: string, index: number) => {
            ySelected = value;
            emit('onYSelect', value, index);
          }
        "
      />
      <UiDropdown
        v-if="!!scenarios.length"
        label="Scenario"
        :options="scenarios"
        :selected-option="scenario"
        @select="onScenario"
      />
      <UiDropdown
        v-if="controlYears && !!years.length"
        label="Year"
        :disabled="disableYears"
        :options="years"
        :selected-option="year"
        @select="(title: string) => emit('onYear', title)"
      />

      <div
        v-if="isCompanyRanking"
        class="flex gap-3"
      >
        <UiFilterPanel
          v-if="controlFilters && regionsIndex != null"
          id="regionDropdown"
          class="max-sm:w-full"
          :titles="[String(filterTitles[regionsIndex])]"
          :blocks="[[...filterPanelBlocks[regionsIndex]]]"
          :disabled="isDoubleChart"
          :dropdown-index="regionsIndex"
          label="Region"
          as-dropdown
          @on-filter-change="onFilterChange"
          @on-clear-click="emit('onClearClick', $event)"
        />

        <UiFilterPanel
          v-if="controlFilters && proteinsIndex != null"
          class="max-sm:w-full"
          :titles="[String(filterTitles[proteinsIndex])]"
          :blocks="[[...filterPanelBlocks[proteinsIndex]]]"
          :disabled="isDoubleChart"
          :dropdown-index="proteinsIndex"
          label="Protein"
          as-dropdown
          @on-filter-change="onFilterChange"
          @on-clear-click="emit('onClearClick', $event)"
        />

        <UiFilterPanel
          v-if="controlFilters && companiesIndex != null"
          class="w-[214px] max-sm:w-full"
          :titles="[String(filterTitles[companiesIndex])]"
          :blocks="[[...filterPanelBlocks[companiesIndex]]]"
          :disabled="isDoubleChart"
          :dropdown-index="companiesIndex"
          label="Companies"
          as-dropdown
          @on-filter-change="onFilterChange"
          @on-clear-click="emit('onClearClick', $event)"
        />
      </div>
      <UiDropdown
        v-if="controlPillars && showPillars"
        id="pillarsDropdown"
        label="Pillar"
        class="relative"
        :options="pillars"
        :disabled="!showPillars"
        :selected-option="pillarSelected"
        @select="(title: string) => emit('onPillar', title)"
      />
    </div>
  </div>
</template>
